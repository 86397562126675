<template>
  <ion-page>
     <Header type="0" :title="$route.params.type==1?'外协模具点检':'自制模具点检'" v-if="menuRole == 'phone'"> </Header>
    <ion-content scroll-y="true" class="content">
      <!-- 无数据  -->
      <div class="no_data" v-if="tableList.length == 0 && !islist">
        <img
          class="no_data_img no_data_top"
          src="@/assets/images/notDate/notSearch.png"
          alt
        />
        <p class="no_data_text">未查询到信息</p>
      </div>
      <div
        class="app-container"
        v-for="dataItem in tableList"
        :key="dataItem.id"
        @click="nav(dataItem)"
      >
        <div class="item" v-if="$route.params.type == 1">
          <div class="item-label">使用负责人</div>
          <div class="item-content">{{ dataItem.mouldDirector }}</div>
        </div>

        <div class="item" v-if="$route.params.type == 1">
          <div class="item-label">供应商名称</div>
          <div class="item-content">{{ dataItem.supplierCN }}</div>
        </div>
        <div class="item" v-if="$route.params.type == 0">
          <div class="item-label">使用负责人</div>
          <div class="item-content">{{ dataItem.principal }}</div>
        </div>
        <div class="item" v-if="$route.params.type == 0">
          <div class="item-label">创建时间</div>
          <div class="item-content">{{ dataItem.createTime }}</div>
        </div>
      </div>
      <CardLoading v-if="islist" lodingType="1"></CardLoading>
    </ion-content>
  </ion-page>
</template>
<script>
import Header from "@/components/Header.vue";
import CardLoading from "@/components/ListCard/CardLoading.vue";
import checkCard from "./checkCard.vue";
import { getMouldList, getMouldListZZ } from "@/api/templateInfo/templateCheck";
import * as dd from "dingtalk-jsapi";
export default {
  components: {
    Header,
    CardLoading,
    checkCard,
  },
  data() {
    return {
      islist: false,
      tableList: [],
      menuRole: "phone",
    };
  },
  mounted() {
    this.getList();
    var _this =this
     this.menuRole = localStorage.getItem("menuRole");
    if (this.menuRole == "dd") {
      dd.ready(function () {
        dd.biz.navigation.setRight({
          show: false, //控制按钮显示， true 显示， false 隐藏， 默认true
          control: true, //是否控制点击事件，true 控制，false 不控制， 默认false
          text: "发送", //控制显示文本，空字符串表示显示默认文本
          onSuccess: function (result) {
            //如果control为true，则onSuccess将在发生按钮点击事件被回调
            /*
        {}
        */
          },
          onFail: function (err) {},
        });
        dd.biz.navigation.setTitle({
          title: _this.$route.params.type==1?"外协模具点检":"自制模具点检", //控制标题文本，空字符串表示显示默认文本
          onSuccess: function (result) {
            /*结构
        {
        }*/
          },
          onFail: function (err) {},
        });
      });
    }
  },
  methods: {
    getList() {
      this.islist = true
      var func;
      switch (this.$route.params.type) {
        case "0":
          func = getMouldListZZ;
          break;
        case "1":
          func = getMouldList;
        default:
          break;
      }
      func().then((res) => {
         this.islist = false
        this.tableList = res.data.data.resultData;
      });
    },
    transfer(type) {
      console.log("img");
      this.$router.push({
        path: "/sedExcel",
      });
    },
    nav(val) {
      console.log(val.isEdit)
      this.$router.push({
        path: this.$route.params.type==1?"/templateCheckInfo/"+ val.id:"/templateCheckInfoZz/" + val.id,
        query: {
          isEdit: val.isEdit,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.app-container {
  width: calc(100% - 2.5rem);
  height: auto;
  background: #ffffff;
  border-radius: 0.875rem;
  margin: 1.25rem auto;
  box-shadow: 0 0 1rem 0 rgba(21, 40, 102, 0.1);
  padding: 1.25rem 2.8125rem;
  font-size: 1.625rem;
  div {
    padding: 5px 0;
  }
  .title {
    width: 100%;
    font-size: 13px;
    font-weight: 700;
  }
  .rate {
    font-size: 15px;
    font-weight: 500;
    color: #1782e3;
  }
  ion-item {
    --background: #ffffff;
    --padding-start: 0;
  }
  .item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .item-label {
      width: 60%;
      font-size: 10px;
      opacity: 0.5;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
    }
  }
}
</style>