import request from "@/utils/request"
import SystemUtils from '@/utils/SystemUtils';

// 获取模具点检列表
export function getMouldList(data){
    return request('/mouldAccountCheck/getMouldList.do',"POST",{userId:SystemUtils.loginUser.id})
}
// 获取自制模具点检列表
export function getMouldListZZ(data){
    return request('/mouldToolingCheck/getMouldList.do',"POST",{$userId:SystemUtils.loginUser.id})
}

//获取模具点检详细信息
export function getMouldInfo(data){
    return request('/mouldAccountCheck/getMouldInfo.do',"POST",data)
}

//模具点检上传图片
export function uploadImage(data){
    return request('/mouldAccountCheck/uploadImage.do',"POST",data)
}

//模具点检删除图片
export function deleteImage(data){
    return request('/mouldAccountCheck/deleteImage.do',"POST",data)
}
//更新模具点检
export function updateCheckItem(data){
    return request('/mouldAccountCheck/updateCheckItem.do',"POST",data)
}
//发送模具点检
export function sendTask(data){
    return request('/mouldAccountCheck/sendTask.do',"POST",Object.assign({userId:SystemUtils.loginUser.id},data))
}
//导出模具点检
export function exportCheckItem(data){
    return request('/mouldAccountCheck/exportCheckItem.do',"POST",data)
}